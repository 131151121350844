"use strict";

import L, { marker } from "leaflet";
import "leaflet/dist/leaflet.css";

class libMap extends HTMLElement {
  static {
    // console.log("Static initializer");
    /* This code is needed to properly load the images in the Leaflet CSS */
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }

  apiData = null;
  isInitialized = false;
  language = "de";
  listObserver = null;
  locationDesc = new Object();
  map = null;
  mapElemId = "map";
  markerDesc = new Object();
  markers = {};
  resultElemId = "locations";

  constructor() {
    super();
    // console.log("Custom element constructed.");
  }

  adoptedCallback() {
    // console.log("Custom element moved to new document.");
  }

  attributeChangedCallback(name, oldValue, newValue) {
    // console.log(`Attribute ${name} has changed.`);
  }

  connectedCallback() {
    // console.log("Custom element added to page.");

    this.mapElemId = this.getAttribute("data-map-id");
    this.resultElemId = this.getAttribute("data-result-id");
    this.language = document.documentElement.lang;

    // // Wenn die aktuelle Seite mit der Karte per HTMX neu geladen wird, führt dies
    // // zu Initialisierungsproblemen in Leaflet.
    // // Aufrufe anderer Seiten und Rückkehr von dort per Link oder Browser-Back-Button
    // // machten keine Probleme.
    // // Ein Unterschied schien darin zu bestehen, dass beim HTMX-Aufruf der aktuellen
    // // Seite der disconnectedCallback nicht aufgerufen wurde.
    // // Daher versucht der folgende Hack, die Karte anderweitig zu entfernen.
    // let that = this;
    // document.body.addEventListener("htmx:beforeRequest", function (evt) {
    //   if (that.map !== null) {
    //     that.map.remove();
    //     that.map = null;
    //     if (document.getElementById(that.mapElemId)) {
    //       let node = document.getElementById(that.mapElemId);
    //       node.parentNode.removeChild(node);
    //     }
    //   }
    // });

    this.init();
  }

  disconnectedCallback() {
    // console.log("Custom element disconnected.");
  }

  adjustZoom() {
    const visibleMarkers = Object.values(this.markers).filter(
      (marker) => marker.visible
    );
    if (visibleMarkers.length > 0) {
      const group = L.featureGroup(
        visibleMarkers.map((marker) => marker.marker)
      );
      this.map.fitBounds(group.getBounds(), { maxZoom: 15, padding: [15, 15] });
    }
  }

  getMarkersOnMap(bounds = this.map.getBounds()) {
    const visibleMarkers = Object.entries(this.markers).filter(
      ([id, marker]) =>
        marker.visible && bounds.contains(marker.marker.getLatLng())
    );
    const mapCenter = this.map.getCenter();
    visibleMarkers.sort(
      ([id1, marker1], [id2, marker2]) =>
        marker1.marker.getLatLng().distanceTo(mapCenter) -
        marker2.marker.getLatLng().distanceTo(mapCenter)
    );
    return visibleMarkers;
  }

  handleSearchResults() {
    //console.log("Handling search results.");
    const matches = document
      .getElementById(this.resultElemId)
      .querySelectorAll("li");
    const ids = [];

    matches.forEach((match) => {
      ids.push(match.getAttribute("data-location-id"));
    });

    if (ids.length > 0) {
      //console.log("Hiding markers except for:", ids);
      this.hideMarkersExcept(ids);
    } else {
      //console.log("Showing all markers.");
      this.showAllMarkers();
    }

    this.adjustZoom();
  }

  hideMarkers(ids) {
    ids.forEach((loc) => this.hideSingleMarker(loc));
  }

  hideMarkersExcept(ids) {
    this.showAllMarkers();
    const markersToToggle = Object.values(this.markers).filter(
      (marker) => !ids.includes(marker.loc)
    );
    const uniqueLocs = [
      ...new Set(markersToToggle.map((marker) => marker.loc)),
    ];
    this.hideMarkers(uniqueLocs);
  }

  hideSingleMarker(id) {
    const togglers = Object.values(this.markers).filter(
      (marker) => marker.loc === id
    );
    togglers.forEach(function (m) {
      m.marker.removeFrom(this.map);
      m.visible = false;
    }, this);
  }

  fetchData() {
    let p;
    let that = this;
    if (this.apiData) {
      p = Promise.resolve(this.apiData);
    } else {
      p = fetch(this.getAttribute("data-fetch"), {
        headers: {
          Accept: "application/json",
        },
      }).then(function (response) {
        that.apiData = response.json();
        return that.apiData;
      });
    }
    return p;
  }

  createMap() {
    let map = L.map(this.mapElemId, { padding: [15, 15] });

    const defaultCenter = [50.93412, 6.93553];
    const defaultZoom = 9;
    const basemap = L.tileLayer(
      "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright" rel="external">OpenStreetMap</a> contributors',
      }
    );
    map.setView(defaultCenter, defaultZoom);

    basemap.addTo(map);
    return map;
  }

  init() {
    // console.log("Initializing custom element.");
    // TODO Verwende Shadow DOM?

    if (document.getElementById(this.mapElemId) === null) {
      const mapDiv = document.createElement("div");
      mapDiv.id = this.mapElemId;
      mapDiv.classList.add("ratio", "w-100");
      this.appendChild(mapDiv);
    }

    this.map = this.createMap();

    this.initListWatcher();
    this.startWatchingList();

    let that = this;
    return this.fetchData()
      .then(function (data) {
        for (const [id, loc] of Object.entries(data)) {
          if (!loc.places) continue;

          const num_places = loc.places.length;
          for (let j = 0; j < num_places; j++) {
            if (
              loc.places[j].geo === null ||
              loc.places[j].geo.lat === undefined ||
              loc.places[j].geo.lon === undefined
            ) {
              continue;
            }

            that.setTemplates(id, loc, j);
            const marker = L.marker([
              loc.places[j].geo.lat,
              loc.places[j].geo.lon,
            ]);
            marker.addTo(that.map).bindPopup(that.markerDesc[id + "_" + j]);
            marker.on("mouseover", function (event) {
              marker.openPopup();
            });
            that.markers[id + "_" + j] = {
              marker: marker,
              visible: true,
              loc: id,
              id: id + "_" + j,
            };
          }
        }
      })
      .then(function () {
        that.map.locate({ setView: true, maxZoom: 12 });
        that.adjustZoom();
      })
      .then(function () {
        that.map.once("moveend", function (ignored) {
          that.map.on("viewreset, moveend", function (event) {
            that.showTopResults(event);
          });
        });
      });
  }

  initListWatcher() {
    //console.log("Initializing list watcher.");
    const callback = (mutationList, observer) => {
      //console.log("List watcher callback.", mutationList);
      this.handleSearchResults();
    };
    this.observer = new MutationObserver(callback);
  }

  setTemplates(id, loc, placeIdx) {
    let placename = "";

    if (
      loc.places.length > 1 &&
      loc.places[placeIdx].name &&
      loc.places[placeIdx].name[this.language] !== undefined &&
      loc.places[placeIdx].name[this.language].length > 0
    ) {
      placename = " - " + loc.places[placeIdx].name[this.language];
    }
    let locname = loc.name[this.language]
      ? loc.name[this.language]
      : loc.name["de"];

    this.markerDesc[
      id + "_" + placeIdx
    ] = `<a href="${loc._url}" rel="external">${locname}</a>${placename}`;

    this.locationDesc[
      id
    ] = `<a class="stretched-link" href="${loc._url}" rel="external">${locname}</a>`;
  }

  showAllMarkers() {
    Object.values(this.markers).forEach((marker) => {
      marker.marker.addTo(this.map);
      marker.visible = true;
    });
  }

  showTopResults(ev, num = 15) {
    //console.log("Showing top ten.");
    this.stopWatchingList();

    const resultContainer = document.getElementById(this.resultElemId);
    if (!resultContainer) {
      return;
    }
    resultContainer.innerHTML = "";

    const resultList = document.createElement("ul");
    resultList.classList.add("list-group");
    resultContainer.appendChild(resultList);

    let li = new Array();
    let seen = new Set();

    const visibleMarkers = this.getMarkersOnMap();

    if (
      Object.entries(visibleMarkers).length ===
      Object.entries(this.markers).length
    ) {
      this.startWatchingList();
      return;
    }

    for (const [id, marker] of Object.entries(visibleMarkers)) {
      const m = marker[1];
      if (!seen.has(m.loc)) {
        seen.add(m.loc);
        li.push(this.locationDesc[m.loc]);

        if (li.length >= num) break;
      }
    }

    li.forEach((item) => {
      const listElem = document.createElement("li");
      listElem.classList.add("list-group-item", "list-group-item-action");
      listElem.innerHTML = item;
      resultList.appendChild(listElem);
    });

    this.startWatchingList();
  }

  startWatchingList() {
    //console.log("Starting list watcher.");
    const targetNode = document.getElementById(this.resultElemId);

    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };

    this.observer.observe(targetNode, config);
  }

  stopWatchingList() {
    //console.log("Stopping list watcher.");
    this.observer.disconnect();
  }
}

if (!customElements.get("digibib-partners-map")) {
  customElements.define("digibib-partners-map", libMap);
}
