// Import all of Bootstrap's JS
import * as bootstrap from "bootstrap";

import "htmx.org";
import "./htmx.js";

import "./img/de.svg";
import "./img/en.svg";
import "./img/hbz_digibib.svg";
import "./img/hbz-weiss-claim.svg";
import "./img/hbz.svg";
import "./img/mkw.svg";

// import './wix/WixMadeforText-Bold.ttf';
// import './wix/WixMadeforText-BoldItalic.ttf';
// import './wix/WixMadeforText-ExtraBold.ttf';
// import './wix/WixMadeforText-ExtraBoldItalic.ttf';
import "./wix/WixMadeforText-Italic.ttf";
// import './wix/WixMadeforText-Medium.ttf';
// import './wix/WixMadeforText-MediumItalic.ttf';
import "./wix/WixMadeforText-Regular.ttf";
import "./wix/WixMadeforText-SemiBold.ttf";
import "./wix/WixMadeforText-SemiBoldItalic.ttf";

import "bootstrap-icons/icons/house-door.svg";
import "bootstrap-icons/icons/chevron-right.svg";

// Import our custom CSS
import "./scss/styles.scss";

import "./partners-map.js";

document.body.addEventListener("htmx:load", function (event) {
  let q_btn = document.querySelector("#q + div > button");
  if (q_btn) {
    q_btn.addEventListener("click", function () {
      document.querySelector("#q").value = "";
      document.querySelector("#q").dispatchEvent(new Event("input"));
    });
  }
});

document.body.addEventListener("htmx:beforeRequest", function (evt) {
  //console.log('htmx:beforeRequest:', evt);
  window._paq.push(["setReferrerUrl", window.location.href]);
});

document.body.addEventListener("htmx:afterSettle", function (evt) {
  //console.log('htmx:afterSettle:', evt);
  // TODO Filter out evt.detail.pathInfo.requestPath === "/locations/find" ?
  // TODO Track errors differently?
  window._paq.push(["setDocumentTitle", document.title]);
  window._paq.push(["setCustomUrl", evt.detail.xhr.responseURL]);
  window._paq.push(["trackPageView"]);
});

document
  .querySelectorAll("[data-lng-switch]:not([disabled])")
  .forEach(function (elem) {
    elem.addEventListener("click", function (event) {
      const paramsString = window.location.search;
      let searchParams = new URLSearchParams(paramsString);
      searchParams.set("lng", elem.dataset.lngSwitch);
      window.location.search = searchParams.toString();
      event.preventDefault();
      return true;
    });
  });

document
  .querySelectorAll("a.disabled, a[disabled], a[aria-disabled='true']")
  .forEach(function (elem) {
    elem.addEventListener("click", function (event) {
      event.preventDefault();
      return false;
    });
  });
