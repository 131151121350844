window.htmx = require("htmx.org");

import Toast from 'bootstrap/js/dist/toast';

function errHandler(evt) {
  const toastContainer = document.getElementById("htmx-msg-container");
  const toastTpl = document.getElementById("htmx-msg-tpl");

  if (evt.detail.successful) {
    // Successful request, clear out alerts?
  } else {
    const toastFrag = toastTpl.content.cloneNode(true);
    toastContainer.appendChild(toastFrag);
    const toast = toastContainer.querySelector("div.toast:last-child");
    const errorTarget = toast.querySelector(".toast-body");

    if (evt.detail.failed && evt.detail.xhr) {
      // Server error with response contents, equivalent to htmx:responseError
      console.warn("Server error", evt.detail);
      const xhr = evt.detail.xhr;
      errorTarget.innerText = `Unexpected server error: ${xhr.status} - ${xhr.statusText}`;
    } else {
      // Unspecified failure, usually caused by network error
      console.error("Unexpected htmx error", evt.detail);
      errorTarget.innerText =
        "Unexpected error, check your connection and try to refresh the page.";
    }

    Toast.getOrCreateInstance(toast, { autohide: false }).show();
  }
}

document.body.addEventListener("htmx:afterRequest", errHandler);
